<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col cols="9" md="9" class="mb-md-0">
            <span class="mr-1">角色名称</span>
              <b-form-input
                v-model="searchParams.name"
                class="d-inline-block"
                style="width:200px"
                placeholder="例如: 管理员"
              />

          </b-col>
          <b-col cols="3" md="3" class="mb-md-0 d-flex flex-row-reverse mb-1">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-if="$can('update', 'role')"
              variant="primary"
              @click="addRole"
            >
              <span class="text-nowrap"> 添加角色 </span>
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-card>

      <!-- table -->
      <vxe-table
        ref="xTable"
        border
        :auto-resize="true"
        :align="allAlign"
        :data="tableData"
        highlight-current-row
        :loading="loading"
        header-row-class-name="false"
        :scroll-x="{enabled: true}"
        :scroll-y="{enabled: true}"
      >
        <vxe-table-column field="id" title="ID"  tooltip width="60"></vxe-table-column>
        <vxe-table-column field="name" title="角色名称" width="150"></vxe-table-column>
        <vxe-table-column field="menuNames" title="权限信息" min-width="120"></vxe-table-column>
        <vxe-table-column field="jumpPage" title="角色首页" width="150"></vxe-table-column>
        <vxe-table-column field="tag" title="权限标记" width="150"></vxe-table-column>
        <vxe-table-column field="status" title="角色状态" width="100" align="center">
          <template v-slot="{ row }">
            <div v-if="$can('status', 'role')">
              <b-form-checkbox
                @change="statusEvent(row.id)"
                :checked=" row.status == 1 ? true:false"
                class="custom-control-primary"
                name="check-button" switch
              />
            </div>
            <div v-else>无权限</div>
          </template>
        </vxe-table-column>

        <vxe-table-column field="action" title="操作" align="center" width="100">
          <template v-slot="{ row }">
            <div class="text-nowrap">

              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25"/>
                </template>
                <b-dropdown-item @click="editEvent(row)" v-if="$can('info', 'role')">
                <feather-icon icon="InfoIcon" class="mr-50"/>
                <span>编辑</span>
                </b-dropdown-item>
                <b-dropdown-item @click="delEvent(row.id)" v-if="$can('delete', 'role')">
                <feather-icon icon="Edit2Icon" class="mr-50"/>
                <span>删除</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </vxe-table-column>
      </vxe-table>

      <div class="d-flex justify-content-between flex-wrap">
        <div class="d-flex align-items-center mb-0 mt-1">
          <Select v-model="searchParams.size" class="mr-1">
            <Option v-for="item in pageOptions" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <span class="text-nowrap"> 共 {{ total }} 条记录 </span>
        </div>
        <div class="d-flex align-items-center">
          <b-pagination v-model="searchParams.page" :total-rows="total" :per-page="searchParams.size" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <div class="d-flex align-items-center mb-0 mt-1 ml-1">
            <span class="text-nowrap">跳至</span>
            <Input @on-enter="pageToChange"
              v-model="pageTo" type="number" number class="mx-1" style="width: 5rem;"/>
            <span class="text-nowrap">页</span>
          </div>
        </div>
      </div>
    </b-card>
    <b-modal
      id="update-modal"
      :visible="shallShowModal"
      title="角色更新"
      centered
      footer-class="d-flex justify-content-between"
      body-class="px-2"
      size="lg"
      static
      no-close-on-backdrop
      @change="shallShowModal = !shallShowModal"
    >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 角色更新 </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="shallShowModal = false" />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div>
        <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1" v-if="$can('update', 'role')" @click="updateForm">
          {{ formUpdate.id > 0 ? '修改' : '添加' }}
        </b-button>
      </div>

      <div>
        <b-button variant="outline-secondary" class="ml-75 cursor-pointer" @click="shallShowModal = false"> 取消 </b-button>
      </div>
    </template>

    <!-- Modal: Body -->
    <div class="d-flex justify-content-between"></div>
      <validation-observer ref="refFormObserver">
        <b-form class="mb-1">
          <b-row>
            <!-- name -->
            <b-col cols="12">
                <b-form-group label="角色名称" label-for="name">
                  <validation-provider name="角色名称" #default="{ errors }" rules="required">
                  <b-form-input
                    id="name" :state="errors.length > 0 ? false:null"
                    v-model="formUpdate.name"
                    trim
                    placeholder="例如：系统管理员"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- jumpPage -->
            <b-col cols="12">
              <b-form-group label="角色首页" label-for="jumpPage">
                <b-form-input
                  id="jumpPage"
                  v-model="formUpdate.jumpPage"
                  trim
                  placeholder="例如：guanliyuan"
                />
              </b-form-group>
            </b-col>

            <!-- tag -->
            <b-col cols="12">
              <b-form-group label="角色标识" label-for="tag">
                <validation-provider name="角色标识" #default="{ errors }" rules="required">
                  <b-form-input
                    id="tag" :state="errors.length > 0 ? false:null"
                    v-model="formUpdate.tag"
                    trim
                    placeholder="例如：guanliyuan"
                  />
                  <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- status -->
            <b-col cols="12">
              <validation-provider name="角色状态" #default="{ errors }" rules="required">
                <b-form-group label="角色状态" label-for="status" :state="errors.length > 0 ? false:null">
                  <b-form-radio-group id="status" class="col-form-label" v-model="formUpdate.status" :options="statusLineOptions" :value="true" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="12">
              <b-form-group label="角色权限" label-for="menuIds">
                <div class="tree">
                  <div class="scollhide">
                    <Tree id="menuIds" :data="formUpdate.menus_tree" show-checkbox ref="tree"></Tree>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

  </b-modal>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { ref, watch, onMounted } from '@vue/composition-api'

import Ripple from 'vue-ripple-directive'

import { roleListApi, roleDelApi, roleStatusApi, roleSaveApi, roleInfoApi } from '@/api/system/authority'

// Notification
import { VBTooltip } from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, integer, digits } from '@validations'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    vSelect,
    formValidation,
    ValidationProvider,
    ValidationObserver,
  },
  setup(props, context) {
    const { $swal, $can } = context.root

    // Use toast
    const toast = useToast()

    // vx-table 表格加载 表格列位置 表格数据源
    const loading = ref(false)
    const allAlign = ref(null)
    const tableData = ref([])

    // 分页查询选择项
    const pageOptions = ref([
      { label: '10 条/页', value: 10 },
      { label: '20 条/页', value: 20 },
      { label: '50 条/页', value: 50 },
    ])

    // 列表记录 记录总数
    const total = ref(0)
    // 查询条件
    const searchParams = ref({
      page: 1, // 分页
      size: 10, // 每页多少记录
      name: '', // 角色名称
    })

    // 页面跳转到
    const pageTo = ref(null)
    // 处理页面跳转到 用户输入, 判断数据是否介入 1 ~ 最大数
    const pageToChange = () => {
      const count = Math.ceil(total.value / searchParams.value.size)
      if (pageTo.value < 1) {
        pageTo.value = 1
      } else if (pageTo.value > count) {
        pageTo.value = count
      } else {
        pageTo.value = parseInt(pageTo.value, 0)
      }

      searchParams.value.page = pageTo.value
    }

    // 编辑表单的 ref
    const refFormObserver = ref(null)
    // 表单对话框的切换属性
    const shallShowModal = ref(false)

    // 表单编辑 status 选择项
    const statusLineOptions = ref([
      { text: '启用', value: true },
      { text: '禁用', value: false },
    ])

    // 表单内容项
    const formUpdate = ref({
      name: '',
      status: 0,
      menuIds: [],
      jumpPage: '',
      id: 0,
      tag: '',
      menus_tree: [],
    })
    // 重置表单数据
    const resetFormData = () => {
      formUpdate.value = {
        name: '',
        status: 0,
        jumpPage: '',
        menuIds: [],
        id: 0,
        tag: '',
        menus_tree: [],
      }
      refFormObserver.value.reset() // 刷新表单验证状态
    }

    // 获取列表数据
    const fetchData = () => {
      roleListApi(searchParams.value)
        .then(res => {
          tableData.value = res.data.list
          total.value = res.data.total
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取角色信息失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    watch(shallShowModal, val => { if (!val) { resetFormData() } }) // 表单对话框关闭时 => 刷新表单数据

    watch(searchParams.value, () => { fetchData() }) // 查询条件变更时 => 发起请求
    // 页面加载 请求数据
    onMounted(() => {
      if ($can('read', 'role')) {
        fetchData()
      } else {
        toast({
          component: ToastificationContent,
          props: { title: '您没有获取角色列表的权限', icon: 'AlertTriangleIcon', variant: 'danger' },
        })
      }
    })

    const getInfo = id => {
      roleInfoApi({ id })
        .then(res => {
          formUpdate.value = res.data
          shallShowModal.value = true
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取角色详情失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    // 添加角色
    const addRole = () => { getInfo(0) }

    // 用户点击编辑按钮，打开对话框
    const editEvent = row => { getInfo(row.id) }

    // 列表切换 状态
    const statusEvent = id => {
      roleStatusApi({ id })
        .then(() => {
          fetchData()
          toast({
            component: ToastificationContent,
            props: { title: '角色信息状态更新成功', icon: 'CheckCircleIcon', variant: 'success' },
          })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '角色信息状态更新失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    // 树状权限 ref tree
    const tree = ref(null)
    // 提交 表单编辑 或 新增
    const updateForm = () => {
      refFormObserver.value.validate().then(success => {
        if (success) {
          formUpdate.value.menuIds = []
          tree.value.getCheckedAndIndeterminateNodes().map(node => { formUpdate.value.menuIds.push(node.id) })
          if (formUpdate.value.menuIds.length === 0) {
            return $swal({
              title: '设置角色必须配置角色对应的功能权限',
              icon: 'error',
              showCancelButton: false,
              confirmButtonText: '确定',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
          }
          // eslint-disable-next-line
          roleSaveApi(formUpdate.value)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: { title: '更新角色信息成功', icon: 'CheckCircleIcon', variant: 'success' },
              })
              fetchData()
              shallShowModal.value = false
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: { title: '更新角色信息失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
              })
            })
        }
      })
    }

    // 删除列表信息
    const delEvent = id => {
      // 弹出确认对话框
      $swal({
        title: '确定删除此角色信息吗?',
        text: '如果存在关联信息,删除角色信息可能会失败!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          roleDelApi({ id })
            .then(() => {
              fetchData()
              toast({
                component: ToastificationContent,
                props: { title: '删除角色信息成功', icon: 'CheckCircleIcon', variant: 'success' },
              })
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: { title: '删除角色信息失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
              })
            })
        }
      })
    }

    return {
      loading,
      allAlign,
      tableData,
      total,

      searchParams,
      pageTo,
      pageToChange,
      pageOptions,
      statusLineOptions,

      fetchData,
      addRole,
      delEvent,
      statusEvent,
      editEvent,
      updateForm,

      shallShowModal,
      tree,
      formUpdate,

      required,
      integer,
      digits,

      refFormObserver,
      resetFormData,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.tree {
  margin-left: 5rem;
  width: 100%;
  height: 25rem;

  .scollhide {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .scollhide::-webkit-scrollbar {
    display: none;
  }
}
</style>
